import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import bild from  './softgewerk.png';
import TimePicker from 'react-time-picker';
import DatePicker from 'react-date-picker';
import moment from 'moment'
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';

function App() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(100);
  const [Daten, setDaten] = useState([]);
  const [bestaetigte, setbestaetigte] = useState(false);
  const [abgesagte, setabgesagte] = useState(false);
  const [unbesTermine, setunbesTermine] = useState(false);
  const [InputFieldZwei, setInputFieldZwei] = useState(false);
  const [grund, setgrund] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [LoadingModal, setLoadingModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [alle, setalle] = useState(false);
  const [v_TerminBestaetigungsdatum, setv_TerminBestaetigungsdatum] = useState('');
  const [v_Dokument_Nummer, setv_Dokument_Nummer] = useState('');
  const [v_Liefer_AdressNummer, setv_Liefer_AdressNummer] = useState('');
  const [v_KdiEingang_AnsprPart, setv_KdiEingang_AnsprPart] = useState('');
  const [v_Kdi_AuftrBeschreibng, setv_Kdi_AuftrBeschreibng] = useState('');
  const [v_KdiTermin_Datum, setv_KdiTermin_Datum] = useState(new Date());
  const [v_KdiTermin_Uhrzeit, setv_KdiTermin_Uhrzeit] = useState('01:00');
  const [v_KdiTermin_Dauer, setv_KdiTermin_Dauer] = useState('');
  const [v_Kunde_Anrede, setv_Kunde_Anrede] = useState('');
  const [v_Kunde_Name1, setv_Kunde_Name1] = useState('');
  const [v_Kunde_Strasse, setv_Kunde_Strasse] = useState('');
  const [v_Kunde_Landeskennz, setv_Kunde_Landeskennz] = useState('');
  const [v_Kunde_Postleitzahl, setv_Kunde_Postleitzahl] = useState('');
  const [v_Kunde_Ort, setv_Kunde_Ort] = useState('');
  const [v_Kdi_BesuchEMail, setv_Kdi_BesuchEMail] = useState('');
  const [v_Vorgang, setv_Vorgang] = useState('');
  const [Laden, setLaden] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState('10:00');
  const [valuee, onChangee] = useState('10:00');
  const [valueee, onChangeee] = useState('10:00');
  const [terminierung, setterminierung] = useState('');
  const [terminierungg, setterminierungg] = useState('');
  const [showTage, setshowTage] = useState(false);
  const [vorschlag, setvorschlag] = useState(28);
  const [erste, seterste] = useState(7);
  const [zweite, setzweite] = useState(3);
  const [dritte, setdritte] = useState(3);
  const [eskalation, seteskalation] = useState(2);
  const [finale, setfinale] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleCloseTage = () => setshowTage(false);
  const handleShowTage = () => setshowTage(true);
  const handleCloseEdit = () => {setShowEdit(false); setInputFieldZwei(false)};
  const handleShow = () => setShow(true);

  const handleTerminAbsagen = () => {
    setInputFieldZwei(true);
  }

  const absagen = async() => {

    try {
      const res = await axios.post('http://wartung.hundertmark-bad.de/absagezwei.php', { 
        id: v_Dokument_Nummer, grund: grund}
        
      
      );
      
      
  
      
      handleCloseEdit();
      setInputFieldZwei(false);
      
     
     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }
  
   
  }

const handleCloseTageSpeichern = async () => {


  try {
    const res = await axios.get('http://wartung.hundertmark-bad.de/TageSpeichern.php', {
      params: { vorschlag: vorschlag, erste: erste, zweite: zweite, dritte: dritte, eskalation: eskalation}, 
      
    
    });
    
    

    
    handleCloseTage();
    Load();
    
   
   
  } catch (err) {
    if (err.response.status === 500) {
      alert('There was a problem with the server');
    } else {
      alert(err.response.data.msg);
    }
    
  }



}

const bestTermine = async () => {
  setbestaetigte(!bestaetigte);
  setabgesagte(false);
  setunbesTermine(false);

  try {
    const res = await axios.get('http://wartung.hundertmark-bad.de/NurBestaetigte.php', {
     
      
    
    });
    
    

    
    setDaten(res.data.daten);
    
   
   
  } catch (err) {
    if (err.response.status === 500) {
      alert('There was a problem with the server');
    } else {
      alert(err.response.data.msg);
    }
    
  }

}




const abTermine = async () => {
  setabgesagte(!abgesagte);
  setbestaetigte(false);
  setunbesTermine(false);



  try {
    const res = await axios.get('http://wartung.hundertmark-bad.de/NurAbgesagte.php', {
     
      
    
    });
    
    

    
    setDaten(res.data.daten);
    
   
   
  } catch (err) {
    if (err.response.status === 500) {
      alert('There was a problem with the server');
    } else {
      alert(err.response.data.msg);
    }
    
  }
}

const faelligeTermine = async () => {
  setfinale(true)
;  setabgesagte(false);
  setbestaetigte(false);
  setunbesTermine(false);



  try {
    const res = await axios.get('http://wartung.hundertmark-bad.de/NurFaellige.php', {
     
      
    
    });
    
    

    
    setDaten(res.data.daten);
    
   
   
  } catch (err) {
    if (err.response.status === 500) {
      alert('There was a problem with the server');
    } else {
      alert(err.response.data.msg);
    }
    
  }
}


const ubTermine = async () => {
  setunbesTermine(!unbesTermine);
  setbestaetigte(false);
  setabgesagte(false);

  try {
    const res = await axios.get('http://wartung.hundertmark-bad.de/NurUnbestaetigte.php', {
     
      
    
    });
    
    

    
    setDaten(res.data.daten);
    
   
   
  } catch (err) {
    if (err.response.status === 500) {
      alert('There was a problem with the server');
    } else {
      alert(err.response.data.msg);
    }
    
  }
}

var p = '';
  const handleShowEdit = (ID,
    v_TerminBestaetigungsdatum,
v_Dokument_Nummer,
v_Liefer_AdressNummer,
v_KdiEingang_AnsprPart,
v_Kdi_AuftrBeschreibng,
v_KdiTermin_Datum,
v_KdiTermin_Uhrzeit,
v_KdiTermin_Dauer,
v_Kunde_Anrede,
v_Kunde_Name1,
v_Kunde_Strasse,
v_Kunde_Landeskennz,
v_Kunde_Postleitzahl,
v_Kunde_Ort,
v_Kdi_BesuchEMail,
v_Vorgang

    
    
    
    ) =>
   { 
    if(Rechte === 'admin' || Rechte === 'Bearbeiter'){
      setShowEdit(true); setInputField(false); 
      setCurrentTerminID(ID);
      setv_TerminBestaetigungsdatum(v_TerminBestaetigungsdatum);
setv_Dokument_Nummer(v_Dokument_Nummer);
setv_Liefer_AdressNummer(v_Liefer_AdressNummer);
setv_KdiEingang_AnsprPart(v_KdiEingang_AnsprPart);
setv_Kdi_AuftrBeschreibng(v_Kdi_AuftrBeschreibng);
p = v_KdiTermin_Datum;
var myArray = v_KdiTermin_Datum.split(".");
var zusammen = myArray[2] + '-' + myArray[1] + '-' + myArray[0];
var parse = new Date(zusammen);
setv_KdiTermin_Datum(parse);
setStartDate(parse);
onChange(v_KdiTermin_Uhrzeit);
onChangeee(v_KdiTermin_Dauer.replaceAll(".", ":"));
setv_KdiTermin_Uhrzeit(v_KdiTermin_Uhrzeit);
setv_KdiTermin_Dauer(v_KdiTermin_Dauer);
setv_Kunde_Landeskennz(v_Kunde_Landeskennz);
setv_Kunde_Anrede(v_Kunde_Anrede);
setv_Kunde_Name1(v_Kunde_Name1);
setv_Kunde_Strasse(v_Kunde_Strasse);
setv_Kunde_Postleitzahl(v_Kunde_Postleitzahl);
setv_Kunde_Ort(v_Kunde_Ort);
setv_Kdi_BesuchEMail(v_Kdi_BesuchEMail);
setv_Vorgang(v_Vorgang);

    }
   
  
  };
 

  const handleBestaetigen = async () => {
    setLoadingModal(true);
    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/Neubestaetigen.php', {
        params: { TerminID: TerminID}, 
        
      
      });
      
      

      
      handleCloseEdit();
      var d = Daten;
      var p = currentPage;
      Load();
      
      currentPosts = d;
      setCurrentPage(p);
      setLoadingModal(false);
     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }

  };

  const handleNeuerTermin = () => {
    setInputField(true);
  };


  const handleDeleteTermin = async () => {
    setLoadingModal(true);
    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/delete.php', {
        params: { TerminID: TerminID}, 
        
      
      });
      
      

    
      handleCloseEdit();
      Load();
      var d = Daten;
      var p = currentPage;
      currentPosts = d;
      setCurrentPage(p);
      setLoadingModal(false);
     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }
    
  };

  const bestaetigen = async () => {
    setLoadingModal(true);
    console.log(terminierung);
    console.log(value);
    console.log(valuee);
    console.log(valueee);
  var all = '';
 if(terminierung === ''){
     
      var mm = v_KdiTermin_Datum.getDate();
      var tt = mm < 10 ? ('0' + mm) : (mm);
      var m = v_KdiTermin_Datum.getMonth() + 1;
      var t = m < 10 ? ('0' + m) : (m);
      var ddd = tt + '.' + t + '.' + v_KdiTermin_Datum.getFullYear();

    

      
      all = ddd;
    
    }else{
      
      var arr = terminierung.split("-");
       all = arr[2]  + '.' + arr[1] + '.' + arr[0];
       if(arr[0].length <= 2){
        all = arr[2]  + '.' + arr[1] + '.20' + arr[0];
       }
       var m = moment(terminierung, 'YYYY-MM-DD');
       if(m.isValid() === false){
        var mm = v_KdiTermin_Datum.getDate();
        var tt = mm < 10 ? ('0' + mm) : (mm);
        var m = v_KdiTermin_Datum.getMonth() + 1;
        var t = m < 10 ? ('0' + m) : (m);
        var ddd = tt + '.' + t + '.' + v_KdiTermin_Datum.getFullYear();
        all = ddd;
       } 

      
    }
    
    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/Selbstbestaetigung.php', {
        params: { TerminID: TerminID, auftragsdatum: all, start: value, dauer: valueee}, 
        
      
      });
      
      

    
      handleCloseEdit();
      Load();
      var d = Daten;
      var p = currentPage;
      currentPosts = d;
      setCurrentPage(p);
      setLoadingModal(false);
     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }
    
  };

  const seiten = (e) => {
    if(e.target.value === 'alle'){
      //setpostsPerPage(e.target.value);
      setalle(true);
      paginate(1);
      currentPosts = Daten;
    }else{
     
      setpostsPerPage(e.target.value);
      setalle(false);
      paginate(1);
    }
 
  }

  const logOut = (e) => {
    localStorage.removeItem("loginData") 
    navigate("/organizer/login");
 
  }

  const Load = async () => {
    setLoading(true);

    if(localStorage.getItem("settings") !== null){
      var einstellungen = localStorage.getItem("settings");
      var parsed = JSON.parse(einstellungen);
     setID(parsed.ID);
     setDokumentenNummer(parsed.DokumentenNummer);
     setKunde_Anrede(parsed.Kunde_Anrede);
setKunde_Name1(parsed.Kunde_Name1);
setKunde_Name2(parsed.Kunde_Name2);
setKunde_Strasse(parsed.Kunde_Strasse);
setKunde_Landeskennz(parsed.Kunde_Landeskennz);
setKunde_Postleitzahl(parsed.Kunde_Postleitzahl);
setKunde_Ort(parsed.Kunde_Ort);
setKdi_BesuchEMail(parsed.Kdi_BesuchEMail);
setDatum_Dokument(parsed.Datum_Dokument);
setLiefer_AdressNummer(parsed.Liefer_AdressNummer);
setLiefer_Anrede(parsed.Liefer_Anrede);
setLiefer_Name1(parsed.Liefer_Name1);
setLiefer_Name2(parsed.Liefer_Name2);
setLiefer_Name3(parsed.Liefer_Name3);
setLiefer_Tel_LandWahl(parsed.Liefer_Tel_LandWahl);
setLiefer_Tel_Vorwahl(parsed.Liefer_Tel_Vorwahl);
setLiefer_Tel_Rufnummer(parsed.Liefer_Tel_Rufnummer);
setLiefer_Tel_Durchwahl(parsed.Liefer_Tel_Durchwahl);
setDatum_Ersterfassung(parsed.Datum_Ersterfassung);
setZeit_Ersterfassung(parsed.Zeit_Ersterfassung);
setDOK_Phase(parsed.DOK_Phase);
setDOK_Disposition_Datum(parsed.DOK_Disposition_Datum);
setDOK_Disposition_Zeit(parsed.DOK_Disposition_Zeit);
setKdi_Gruppe(parsed.Kdi_Gruppe);
setKdiWartung_VertragNr(parsed.KdiWartung_VertragNr);
setKdiEingang_AnsprPart(parsed.KdiEingang_AnsprPart);
setKdi_AuftrBeschreibng(parsed.Kdi_AuftrBeschreibng);
setKdiTermin_Datum(parsed.KdiTermin_Datum);
setKdiTermin_Uhrzeit(parsed.KdiTermin_Uhrzeit);
setKdiTermin_BisUhrzeit(parsed.KdiTermin_BisUhrzeit);
setKdiTermin_Dauer(parsed.KdiTermin_Dauer);
setKdi_RechEMail(parsed.Kdi_RechEMail);
setKdi_Terminwunsch(parsed.Kdi_Terminwunsch);
setStoerungscode(parsed.Stoerungscode);
setKdi_GebaeudeKomplex(parsed.Kdi_GebaeudeKomplex);
setKdi_Gebaeude(parsed.Kdi_Gebaeude);
setKDI_WVAnlageArt(parsed.KDI_WVAnlageArt);
setTerminBestaetigungsdatum(parsed.TerminBestaetigungsdatum);
setLiefer_Strasse(parsed.Liefer_Strasse);
setLiefer_Postleitzahl(parsed.Liefer_Postleitzahl);
setLiefer_Ort(parsed.Liefer_Ort);
setKunde_Tel_LandWahl(parsed.Kunde_Tel_LandWahl);
setKunde_Tel_Vorwahl(parsed.Kunde_Tel_Vorwahl);
setKunde_Tel_Rufnummer(parsed.Kunde_Tel_Rufnummer);
setKunde_Tel_Durchwahl(parsed.Kunde_Tel_Durchwahl);


      
    }else{
      var obj = {
        ID : ID,
        DokumentenNummer: DokumentenNummer,
        Kunde_Anrede : Kunde_Anrede,
Kunde_Name1 : Kunde_Name1,
Kunde_Name2 : Kunde_Name2,
Kunde_Strasse : Kunde_Strasse,
Kunde_Landeskennz : Kunde_Landeskennz, 
Kunde_Postleitzahl : Kunde_Postleitzahl,
Kunde_Ort : Kunde_Ort,
Kdi_BesuchEMail : Kdi_BesuchEMail,
Datum_Dokument : Datum_Dokument,
Liefer_AdressNummer : Liefer_AdressNummer,
Liefer_Anrede : Liefer_Anrede,
Liefer_Name1 : Liefer_Name1,
Liefer_Name2 : Liefer_Name2,
Liefer_Name3 : Liefer_Name3,
Liefer_Tel_LandWahl : Liefer_Tel_LandWahl, 
Liefer_Tel_Vorwahl : Liefer_Tel_Vorwahl,
Liefer_Tel_Rufnummer : Liefer_Tel_Rufnummer,
Liefer_Tel_Durchwahl : Liefer_Tel_Durchwahl,
Datum_Ersterfassung : Datum_Ersterfassung,
Zeit_Ersterfassung : Zeit_Ersterfassung,
DOK_Phase : DOK_Phase,
DOK_Disposition_Datum : DOK_Disposition_Datum,
DOK_Disposition_Zeit : DOK_Disposition_Zeit,
Kdi_Gruppe : Kdi_Gruppe,
KdiWartung_VertragNr : KdiWartung_VertragNr,
KdiEingang_AnsprPart : KdiEingang_AnsprPart,
Kdi_AuftrBeschreibng : Kdi_AuftrBeschreibng,
KdiTermin_Datum : KdiTermin_Datum,
KdiTermin_Uhrzeit : KdiTermin_Uhrzeit,
KdiTermin_BisUhrzeit : KdiTermin_BisUhrzeit,
KdiTermin_Dauer : KdiTermin_Dauer,
Kdi_RechEMail : Kdi_RechEMail,
Kdi_Terminwunsch : Kdi_Terminwunsch,
Stoerungscode : Stoerungscode,
Kdi_GebaeudeKomplex : Kdi_GebaeudeKomplex,
Kdi_Gebaeude : Kdi_Gebaeude,
KDI_WVAnlageArt : KDI_WVAnlageArt, 
TerminBestaetigungsdatum : TerminBestaetigungsdatum,
Liefer_Strasse : Liefer_Strasse,
Liefer_Postleitzahl : Liefer_Postleitzahl,
Liefer_Ort : Liefer_Ort,
Kunde_Tel_LandWahl : Kunde_Tel_LandWahl,
Kunde_Tel_Vorwahl : Kunde_Tel_Vorwahl,
Kunde_Tel_Rufnummer : Kunde_Tel_Rufnummer,
Kunde_Tel_Durchwahl : Kunde_Tel_Durchwahl,


        
      }
     
      localStorage.setItem("settings", JSON.stringify(obj)); 
    }


    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/load.php', {
        
      
      });
      
      

     setDaten(res.data.daten);
     setLoading(false);

     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }


    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/TageHolen.php', {
        
      
      });
      
      

    
     setvorschlag(res.data.TageBescheid);
seterste(res.data.ersteEskalationTage);
setzweite(res.data.zweiteEskalationTage);
setdritte(res.data.dritteEskalationTage);
seteskalation(res.data.eskalation);
  

     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }
  };





  const [ID, setID] = useState(true);
  const [DokumentenNummer, setDokumentenNummer] = useState(true);
  const [Kunde_Anrede, setKunde_Anrede] = useState(true);
  const [Kunde_Name1, setKunde_Name1] = useState(true);
  const [Kunde_Name2, setKunde_Name2] = useState(true);
  const [Kunde_Strasse, setKunde_Strasse] = useState(true);
  const [Kunde_Landeskennz, setKunde_Landeskennz] = useState(true);
  const [Kunde_Postleitzahl, setKunde_Postleitzahl] = useState(true);
  const [Kunde_Ort, setKunde_Ort] = useState(true);
  const [Kdi_BesuchEMail, setKdi_BesuchEMail] = useState(true);
  const [Datum_Dokument, setDatum_Dokument] = useState(true);
  const [Liefer_AdressNummer, setLiefer_AdressNummer] = useState(true);
  const [Liefer_Anrede, setLiefer_Anrede] = useState(true);
  const [Liefer_Name1, setLiefer_Name1] = useState(true);
  const [Liefer_Name2, setLiefer_Name2] = useState(true);
  const [Liefer_Name3, setLiefer_Name3] = useState(true);
  const [Liefer_Tel_LandWahl, setLiefer_Tel_LandWahl] = useState(true);
  const [Liefer_Tel_Vorwahl, setLiefer_Tel_Vorwahl] = useState(true);
  const [Liefer_Tel_Rufnummer, setLiefer_Tel_Rufnummer] = useState(true);
  const [Liefer_Tel_Durchwahl, setLiefer_Tel_Durchwahl] = useState(true);
  const [Datum_Ersterfassung, setDatum_Ersterfassung] = useState(true);
  const [Zeit_Ersterfassung, setZeit_Ersterfassung] = useState(true);
  const [DOK_Phase, setDOK_Phase] = useState(false);
  const [DOK_Disposition_Datum, setDOK_Disposition_Datum] = useState(false);
  const [DOK_Disposition_Zeit, setDOK_Disposition_Zeit] = useState(false);
  const [Kdi_Gruppe, setKdi_Gruppe] = useState(true);
  const [KdiWartung_VertragNr, setKdiWartung_VertragNr] = useState(true);
  const [KdiEingang_AnsprPart, setKdiEingang_AnsprPart] = useState(true);
  const [Kdi_AuftrBeschreibng, setKdi_AuftrBeschreibng] = useState(true);
  const [KdiTermin_Datum, setKdiTermin_Datum] = useState(true);
  const [KdiTermin_Uhrzeit, setKdiTermin_Uhrzeit] = useState(true);
  const [KdiTermin_BisUhrzeit, setKdiTermin_BisUhrzeit] = useState(true);
  const [KdiTermin_Dauer, setKdiTermin_Dauer] = useState(true);
  const [Kdi_RechEMail, setKdi_RechEMail] = useState(true);
  const [Kdi_Terminwunsch, setKdi_Terminwunsch] = useState(false);
  const [Stoerungscode, setStoerungscode] = useState(false);
  const [Kdi_GebaeudeKomplex, setKdi_GebaeudeKomplex] = useState(true);
  const [Kdi_Gebaeude, setKdi_Gebaeude] = useState(true);
  const [KDI_WVAnlageArt, setKDI_WVAnlageArt] = useState(true);
  const [TerminBestaetigungsdatum, setTerminBestaetigungsdatum] = useState(true);
  const [InputField, setInputField] = useState(false);
  const [TerminID, setCurrentTerminID] = useState(0);
  const [Rechte, setRechte] = useState('Leser');
  const [Liefer_Strasse, setLiefer_Strasse] = useState(true);
  const [Liefer_Postleitzahl, setLiefer_Postleitzahl] = useState(true);
  const [Liefer_Ort, setLiefer_Ort] = useState(true);
  const [Kunde_Tel_LandWahl, setKunde_Tel_LandWahl] = useState(true);
  const [Kunde_Tel_Vorwahl, setKunde_Tel_Vorwahl] = useState(true);
  const [Kunde_Tel_Rufnummer, setKunde_Tel_Rufnummer] = useState(true);
  const [Kunde_Tel_Durchwahl, setKunde_Tel_Durchwahl] = useState(true);
  const [Vorgang, setVorgang] = useState(true);


  useEffect(() => {  
    
    if (localStorage.getItem("loginData") == null) {
            
      navigate("/organizer/login");
 
      
    } else{
      var loginData = localStorage.getItem("loginData");
      var loginDataparsed = JSON.parse(loginData);
      setRechte(loginDataparsed.username);
    
    }

    
    const onLoad = async () => {
      setLoading(true);

      if(localStorage.getItem("settings") !== null){
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
       setID(parsed.ID);
       setDokumentenNummer(parsed.DokumentenNummer);
       setKunde_Anrede(parsed.Kunde_Anrede);
setKunde_Name1(parsed.Kunde_Name1);
setKunde_Strasse(parsed.Kunde_Strasse);
setKunde_Landeskennz(parsed.Kunde_Landeskennz);
setKunde_Postleitzahl(parsed.Kunde_Postleitzahl);
setKunde_Ort(parsed.Kunde_Ort);
setKdi_BesuchEMail(parsed.Kdi_BesuchEMail);
setLiefer_AdressNummer(parsed.Liefer_AdressNummer);
setKdiEingang_AnsprPart(parsed.KdiEingang_AnsprPart);
setKdi_AuftrBeschreibng(parsed.Kdi_AuftrBeschreibng);
setKdiTermin_Datum(parsed.KdiTermin_Datum);
setKdiTermin_Uhrzeit(parsed.KdiTermin_Uhrzeit);
setKdiTermin_Dauer(parsed.KdiTermin_Dauer);
setTerminBestaetigungsdatum(parsed.TerminBestaetigungsdatum);
setVorgang(parsed.Vorgang);


        
      }else{
        var obj = {
          ID : ID,
          DokumentenNummer: DokumentenNummer,
          Kunde_Anrede : Kunde_Anrede,
Kunde_Name1 : Kunde_Name1,
Kunde_Strasse : Kunde_Strasse,
Kunde_Landeskennz : Kunde_Landeskennz, 
Kunde_Postleitzahl : Kunde_Postleitzahl,
Kunde_Ort : Kunde_Ort,
Kdi_BesuchEMail : Kdi_BesuchEMail,
Liefer_AdressNummer : Liefer_AdressNummer,
KdiEingang_AnsprPart : KdiEingang_AnsprPart,
Kdi_AuftrBeschreibng : Kdi_AuftrBeschreibng,
KdiTermin_Datum : KdiTermin_Datum,
KdiTermin_Uhrzeit : KdiTermin_Uhrzeit,
KdiTermin_Dauer : KdiTermin_Dauer,
TerminBestaetigungsdatum : TerminBestaetigungsdatum,
Vorgang : Vorgang
          
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 
      }


      try {
        const res = await axios.get('http://wartung.hundertmark-bad.de/load.php', {
          
        
        });
        
        
  
       setDaten(res.data.daten);
       setLoading(false);
  
       
      } catch (err) {
        if (err.response.status === 500) {
          alert('There was a problem with the server');
        } else {
          alert(err.response.data.msg);
        }
        
      }

      try {
        const res = await axios.get('http://wartung.hundertmark-bad.de/TageHolen.php', {
          
        
        });
        
        
  
      
       setvorschlag(res.data.TageBescheid);
  seterste(res.data.ersteEskalationTage);
  setzweite(res.data.zweiteEskalationTage);
  setdritte(res.data.dritteEskalationTage);
  seteskalation(res.data.eskalation);
    
  
       
      } catch (err) {
        if (err.response.status === 500) {
          alert('There was a problem with the server');
        } else {
          alert(err.response.data.msg);
        }
        
      }

   
    };

     onLoad();

  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  var currentPosts = Daten.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  }

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(Daten.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

const search = async (d) => {

  setLoading(true);
  
  var val = d.target.value;
  var vall = val.replaceAll("'", "");


  if(bestaetigte){

    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/autocompleteBes.php',  {
        params: {val: vall}
      
      });
      
      

     setDaten(res.data.daten);
     setCurrentPage(1);
     setLoading(false);

     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }

  } else if (abgesagte){

    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/autocompleteAB.php',  {
        params: {val: vall}
      
      });
      
      

     setDaten(res.data.daten);
     setCurrentPage(1);
     setLoading(false);

     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }

  } else if (unbesTermine){

    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/autocompleteUT.php',  {
        params: {val: vall}
      
      });
      
      

     setDaten(res.data.daten);
     setCurrentPage(1);
     setLoading(false);

     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }

  }  

  else if (finale){

    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/autocompleteU.php',  {
        params: {val: vall}
      
      });
      
      

     setDaten(res.data.daten);
     setCurrentPage(1);
     setLoading(false);

     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }

  }  
  
  
  
  else {
    try {
      const res = await axios.get('http://wartung.hundertmark-bad.de/autocomplete.php',  {
        params: {val: vall}
      
      });
      
      

     setDaten(res.data.daten);
     setCurrentPage(1);
     setLoading(false);

     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }
  }
 
  
}



  const filterc = (bez) => {

    if(bez === 'ID'){
      setID(!ID);
      if (localStorage.getItem("settings") === null) {

        var obj = {
          ID: !ID
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.ID = !ID;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'DokumentenNummer'){
      setDokumentenNummer(!DokumentenNummer)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          DokumentenNummer: !DokumentenNummer
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.DokumentenNummer = !DokumentenNummer;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Anrede'){
      setKunde_Anrede(!Kunde_Anrede)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kunde_Anrede: !Kunde_Anrede
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Anrede = !Kunde_Anrede;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Vorgang'){
      setVorgang(!Vorgang)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Vorgang: !Vorgang
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Vorgang = !Vorgang;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }


    if(bez === 'Kunde_Name1'){
      setKunde_Name1(!Kunde_Name1)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kunde_Name1: !Kunde_Name1
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Name1 = !Kunde_Name1;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Name2'){
      setKunde_Name2(!Kunde_Name2)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kunde_Name2: !Kunde_Name2
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Name2 = !Kunde_Name2;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Strasse'){
      setKunde_Strasse(!Kunde_Strasse)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kunde_Strasse: !Kunde_Strasse
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Strasse = !Kunde_Strasse;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Landeskennz'){
      setKunde_Landeskennz(!Kunde_Landeskennz)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kunde_Landeskennz: !Kunde_Landeskennz
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Landeskennz = !Kunde_Landeskennz;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Postleitzahl'){
      setKunde_Postleitzahl(!Kunde_Postleitzahl)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kunde_Postleitzahl: !Kunde_Postleitzahl
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Postleitzahl = !Kunde_Postleitzahl;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Ort'){
      setKunde_Ort(!Kunde_Ort)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kunde_Ort: !Kunde_Ort
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Ort = !Kunde_Ort;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kdi_BesuchEMail'){
      setKdi_BesuchEMail(!Kdi_BesuchEMail)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kdi_BesuchEMail: !Kdi_BesuchEMail
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kdi_BesuchEMail = !Kdi_BesuchEMail;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Datum_Dokument'){
      setDatum_Dokument(!Datum_Dokument)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Datum_Dokument: !Datum_Dokument
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Datum_Dokument = !Datum_Dokument;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_AdressNummer'){
      setLiefer_AdressNummer(!Liefer_AdressNummer)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_AdressNummer: !Liefer_AdressNummer
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_AdressNummer = !Liefer_AdressNummer;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Anrede'){
      setLiefer_Anrede(!Liefer_Anrede)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_Anrede: !Liefer_Anrede
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Anrede = !Liefer_Anrede;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Name1'){
      setLiefer_Name1(!Liefer_Name1)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_Name1: !Liefer_Name1
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Name1 = !Liefer_Name1;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Name2'){
      setLiefer_Name2(!Liefer_Name2)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_Name2: !Liefer_Name2
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Name2 = !Liefer_Name2;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Name3'){
      setLiefer_Name3(!Liefer_Name3)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_Name3: !Liefer_Name3
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Name3 = !Liefer_Name3;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Tel_LandWahl'){
      setLiefer_Tel_LandWahl(!Liefer_Tel_LandWahl)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_Tel_LandWahl: !Liefer_Tel_LandWahl
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Tel_LandWahl = !Liefer_Tel_LandWahl;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Tel_Vorwahl'){
      setLiefer_Tel_Vorwahl(!Liefer_Tel_Vorwahl)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_Tel_Vorwahl: !Liefer_Tel_Vorwahl
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Tel_Vorwahl = !Liefer_Tel_Vorwahl;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Tel_Rufnummer'){
      setLiefer_Tel_Rufnummer(!Liefer_Tel_Rufnummer)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_Tel_Rufnummer: !Liefer_Tel_Rufnummer
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Tel_Rufnummer = !Liefer_Tel_Rufnummer;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Tel_Durchwahl'){
      setLiefer_Tel_Durchwahl(!Liefer_Tel_Durchwahl)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Liefer_Tel_Durchwahl: !Liefer_Tel_Durchwahl
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Tel_Durchwahl = !Liefer_Tel_Durchwahl;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Datum_Ersterfassung'){
      setDatum_Ersterfassung(!Datum_Ersterfassung)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Datum_Ersterfassung: !Datum_Ersterfassung
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Datum_Ersterfassung = !Datum_Ersterfassung;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Zeit_Ersterfassung'){
      setZeit_Ersterfassung(!Zeit_Ersterfassung)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Zeit_Ersterfassung: !Zeit_Ersterfassung
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Zeit_Ersterfassung = !Zeit_Ersterfassung;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'DOK_Phase'){
      setDOK_Phase(!DOK_Phase)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          DOK_Phase: !DOK_Phase
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.DOK_Phase = !DOK_Phase;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'DOK_Disposition_Datum'){
      setDOK_Disposition_Datum(!DOK_Disposition_Datum)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          DOK_Disposition_Datum: !DOK_Disposition_Datum
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.DOK_Disposition_Datum = !DOK_Disposition_Datum;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'DOK_Disposition_Zeit'){
      setDOK_Disposition_Zeit(!DOK_Disposition_Zeit)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          DOK_Disposition_Zeit: !DOK_Disposition_Zeit
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.DOK_Disposition_Zeit = !DOK_Disposition_Zeit;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kdi_Gruppe'){
      setKdi_Gruppe(!Kdi_Gruppe)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kdi_Gruppe: !Kdi_Gruppe
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kdi_Gruppe = !Kdi_Gruppe;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'KdiWartung_VertragNr'){
      setKdiWartung_VertragNr(!KdiWartung_VertragNr)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          KdiWartung_VertragNr: !KdiWartung_VertragNr
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.KdiWartung_VertragNr = !KdiWartung_VertragNr;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'KdiEingang_AnsprPart'){
      setKdiEingang_AnsprPart(!KdiEingang_AnsprPart)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          KdiEingang_AnsprPart: !KdiEingang_AnsprPart
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.KdiEingang_AnsprPart = !KdiEingang_AnsprPart;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kdi_AuftrBeschreibng'){
      setKdi_AuftrBeschreibng(!Kdi_AuftrBeschreibng)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kdi_AuftrBeschreibng: !Kdi_AuftrBeschreibng
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kdi_AuftrBeschreibng = !Kdi_AuftrBeschreibng;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'KdiTermin_Datum'){
      setKdiTermin_Datum(!KdiTermin_Datum)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          KdiTermin_Datum: !KdiTermin_Datum
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.KdiTermin_Datum = !KdiTermin_Datum;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'KdiTermin_Uhrzeit'){
      setKdiTermin_Uhrzeit(!KdiTermin_Uhrzeit)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          KdiTermin_Uhrzeit: !KdiTermin_Uhrzeit
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.KdiTermin_Uhrzeit = !KdiTermin_Uhrzeit;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'KdiTermin_BisUhrzeit'){
      setKdiTermin_BisUhrzeit(!KdiTermin_BisUhrzeit)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          KdiTermin_BisUhrzeit: !KdiTermin_BisUhrzeit
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.KdiTermin_BisUhrzeit = !KdiTermin_BisUhrzeit;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'KdiTermin_Dauer'){
      setKdiTermin_Dauer(!KdiTermin_Dauer)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          KdiTermin_Dauer: !KdiTermin_Dauer
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.KdiTermin_Dauer = !KdiTermin_Dauer;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kdi_RechEMail'){
      setKdi_RechEMail(!Kdi_RechEMail)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kdi_RechEMail: !Kdi_RechEMail
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kdi_RechEMail = !Kdi_RechEMail;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kdi_Terminwunsch'){
      setKdi_Terminwunsch(!Kdi_Terminwunsch)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kdi_Terminwunsch: !Kdi_Terminwunsch
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kdi_Terminwunsch = !Kdi_Terminwunsch;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Stoerungscode'){
      setStoerungscode(!Stoerungscode)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Stoerungscode: !Stoerungscode
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Stoerungscode = !Stoerungscode;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kdi_GebaeudeKomplex'){
      setKdi_GebaeudeKomplex(!Kdi_GebaeudeKomplex)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kdi_GebaeudeKomplex: !Kdi_GebaeudeKomplex
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kdi_GebaeudeKomplex = !Kdi_GebaeudeKomplex;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kdi_Gebaeude'){
      setKdi_Gebaeude(!Kdi_Gebaeude)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          Kdi_Gebaeude: !Kdi_Gebaeude
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kdi_Gebaeude = !Kdi_Gebaeude;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'KDI_WVAnlageArt'){
      setKDI_WVAnlageArt(!KDI_WVAnlageArt)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          KDI_WVAnlageArt: !KDI_WVAnlageArt
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.KDI_WVAnlageArt = !KDI_WVAnlageArt;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'TerminBestaetigungsdatum'){
      setTerminBestaetigungsdatum(!TerminBestaetigungsdatum)
      if (localStorage.getItem("settings") === null) {
        
        var obj = {
          TerminBestaetigungsdatum: !TerminBestaetigungsdatum
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.TerminBestaetigungsdatum = !TerminBestaetigungsdatum;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Strasse'){
      setLiefer_Strasse(!Liefer_Strasse);
      if (localStorage.getItem("settings") === null) {

        var obj = {
          Liefer_Strasse: !Liefer_Strasse
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Strasse = !Liefer_Strasse;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Postleitzahl'){
      setLiefer_Postleitzahl(!Liefer_Postleitzahl);
      if (localStorage.getItem("settings") === null) {

        var obj = {
          Liefer_Postleitzahl: !Liefer_Postleitzahl
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Postleitzahl = !Liefer_Postleitzahl;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Liefer_Ort'){
      setLiefer_Ort(!Liefer_Ort);
      if (localStorage.getItem("settings") === null) {

        var obj = {
          Liefer_Ort: !Liefer_Ort
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Liefer_Ort = !Liefer_Ort;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Tel_LandWahl'){
      setKunde_Tel_LandWahl(!Kunde_Tel_LandWahl);
      if (localStorage.getItem("settings") === null) {

        var obj = {
          Kunde_Tel_LandWahl: !Kunde_Tel_LandWahl
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Tel_LandWahl = !Kunde_Tel_LandWahl;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Tel_Vorwahl'){
      setKunde_Tel_Vorwahl(!Kunde_Tel_Vorwahl);
      if (localStorage.getItem("settings") === null) {

        var obj = {
          Kunde_Tel_Vorwahl: !Kunde_Tel_Vorwahl
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Tel_Vorwahl = !Kunde_Tel_Vorwahl;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Tel_Rufnummer'){
      setKunde_Tel_Rufnummer(!Kunde_Tel_Rufnummer);
      if (localStorage.getItem("settings") === null) {

        var obj = {
          Kunde_Tel_Rufnummer: !Kunde_Tel_Rufnummer
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Tel_Rufnummer = !Kunde_Tel_Rufnummer;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }

    if(bez === 'Kunde_Tel_Durchwahl'){
      setKunde_Tel_Durchwahl(!Kunde_Tel_Durchwahl);
      if (localStorage.getItem("settings") === null) {

        var obj = {
          Kunde_Tel_Durchwahl: !Kunde_Tel_Durchwahl
        }
       
        localStorage.setItem("settings", JSON.stringify(obj)); 

      } else {
        var einstellungen = localStorage.getItem("settings");
        var parsed = JSON.parse(einstellungen);
        parsed.Kunde_Tel_Durchwahl = !Kunde_Tel_Durchwahl;
        localStorage.removeItem("settings");
        localStorage.setItem("settings", JSON.stringify(parsed)); 

      }
    }




  }

  const [showSpaltenWerte, setshowSpaltenWerte] = useState(false);
  const handleCloseSpaltenWerte = () => setshowSpaltenWerte(false);
  const handleShowSpaltenWerte = () => setshowSpaltenWerte(true);

  const editSpaltenWerte = () => {

    handleShowSpaltenWerte(); 
    handleCloseEdit();
  }

  const handleCloseSpeichern = async () => {
    var all = '';
    setLaden(true);
    if(terminierungg === ''){
     
      var mm = v_KdiTermin_Datum.getDate();
      var tt = mm < 10 ? ('0' + mm) : (mm);
      var m = v_KdiTermin_Datum.getMonth() + 1;
      var t = m < 10 ? ('0' + m) : (m);
      var ddd = tt + '.' + t + '.' + v_KdiTermin_Datum.getFullYear();

    

      
      all = ddd;
    
    }else{
      
      var arr = terminierungg.split("-");
       all = arr[2]  + '.' + arr[1] + '.' + arr[0];
       if(arr[0].length <= 2){
        all = arr[2]  + '.' + arr[1] + '.20' + arr[0];
       }
       var m = moment(terminierungg, 'YYYY-MM-DD');
       if(m.isValid() === false){
        var mm = v_KdiTermin_Datum.getDate();
        var tt = mm < 10 ? ('0' + mm) : (mm);
        var m = v_KdiTermin_Datum.getMonth() + 1;
        var t = m < 10 ? ('0' + m) : (m);
        var ddd = tt + '.' + t + '.' + v_KdiTermin_Datum.getFullYear();
        all = ddd;
       } 
    }
 
    var params = {
      TerminID: TerminID, 
      v_Dokument_Nummer: v_Dokument_Nummer,
      v_TerminBestaetigungsdatum: v_TerminBestaetigungsdatum,
      v_Liefer_AdressNummer: v_Liefer_AdressNummer,
      v_KdiEingang_AnsprPart: v_KdiEingang_AnsprPart,
      v_Kdi_AuftrBeschreibng: v_Kdi_AuftrBeschreibng,
      v_KdiTermin_Datum: all,
      v_KdiTermin_Uhrzeit: v_KdiTermin_Uhrzeit,
      v_KdiTermin_Dauer: v_KdiTermin_Dauer,
      v_Kunde_Anrede: v_Kunde_Anrede,
      v_Kunde_Name1: v_Kunde_Name1,
      v_Kunde_Strasse: v_Kunde_Strasse,
      v_Kunde_Landeskennz: v_Kunde_Landeskennz,
      v_Kunde_Postleitzahl: v_Kunde_Postleitzahl,
      v_Kunde_Ort: v_Kunde_Ort,
      v_Kdi_BesuchEMail: v_Kdi_BesuchEMail,
      v_Vorgang: v_Vorgang

      
  }
    try {
      const res = await axios.post('http://wartung.hundertmark-bad.de/Edit.php', params).then(function(response) {
      
      
      });
      
      

      Load();
      handleCloseSpaltenWerte();
      setLaden(false);
      
     
    
      
     
    } catch (err) {
      if (err.response.status === 500) {
        alert('There was a problem with the server');
      } else {
        alert(err.response.data.msg);
      }
      
    }


   
  }
var ff = '';
  const dd = (f) => {
    var e = new Date (f);
    var mm = e.getDate();
    var tt = mm < 10 ? ('0' + mm) : (mm);
    var m = e.getMonth() + 1;
    var t = m < 10 ? ('0' + m) : (m);
    var ddd = tt + '.' + t + '.' + e.getFullYear();
    var arr = ddd.split(".");
    var all = arr[2]  + '-' + arr[1] + '-' + arr[0];
    setterminierungg(all);
    ff = all;
    console.log(ff);

  }

  var fff = '';
  const tre = async (f) => {
    var e = new Date (f);
    var mm = e.getDate();
    var tt = mm < 10 ? ('0' + mm) : (mm);
    var m = e.getMonth() + 1;
    var t = m < 10 ? ('0' + m) : (m);
    var ddd = tt + '.' + t + '.' + e.getFullYear();
    var arr = ddd.split(".");
    var all = arr[2]  + '-' + arr[1] + '-' + arr[0];
    setterminierung(all);
    fff = all;
    console.log(fff);
    console.log(value);
    console.log(valuee);
    console.log(valueee);


  }


  return (


    
    <Container className='hintergrund'>

<Modal show={showSpaltenWerte} onHide={handleCloseSpaltenWerte}>
        <Modal.Header closeButton>
          <Modal.Title>Werte anpassen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {Laden === true ? (
 <Row>
 <Col  xs={12} className="text-center">
 <Spinner animation="border" variant="primary" />
 </Col>

</Row>
) : (

        <Form>
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              
             

               <Col sm="12">
                 <span>Terminbestätigungsdatum</span> <br></br>
                 <Form.Control onChange={(e) => setv_TerminBestaetigungsdatum(e.target.value)} value={v_TerminBestaetigungsdatum} type="text" /> <br></br>
               </Col>

               <Col sm="12">
                 <span>Montageauftragsnummer</span> <br></br>
                 <Form.Control onChange={(e) => setv_Dokument_Nummer(e.target.value)} value={v_Dokument_Nummer} type="text" /> <br></br>
               </Col>

               <Col sm="12">
                 <span>Vorgangsnummer</span> <br></br>
                 <Form.Control onChange={(e) => setv_Vorgang(e.target.value)} value={v_Vorgang} type="text" /> <br></br>
               </Col>

        

               <Col sm="12">
                 <span>Kundennummer</span> <br></br>
                 <Form.Control onChange={(e) => setv_Liefer_AdressNummer(e.target.value)} value={v_Liefer_AdressNummer} type="text" /> <br></br>
               </Col>

  
               <Col sm="12">
                 <span>Art</span> <br></br>
                 <Form.Control onChange={(e) => setv_KdiEingang_AnsprPart(e.target.value)} value={v_KdiEingang_AnsprPart} type="text" /> <br></br>
               </Col>

               <Col sm="12">
                 <span>Auftragsbeschreibung</span> <br></br>
                 <Form.Control onChange={(e) => setv_Kdi_AuftrBeschreibng(e.target.value)} value={v_Kdi_AuftrBeschreibng} type="text" /> <br></br>
               </Col>

               <Col sm="12">
                 <span>Auftragsdatum</span> <br></br>
                 <DatePicker disableCalendar={true} format={"dd-MM-yyyy"} onChange={dd} value={v_KdiTermin_Datum} />
              <br></br>
               </Col>

               <Col sm="12">
               <br></br>
                 <span>Beginn</span> <br></br>
                 <TimePicker disableClock={true} format={"HH:mm"} onChange={setv_KdiTermin_Uhrzeit} value={v_KdiTermin_Uhrzeit} />
              
               </Col>


               <Col sm="12">
               <br></br>
                 <span>Planstunden</span> <br></br>
                 <TimePicker disableClock={true} format={"HH:mm"} onChange={setv_KdiTermin_Dauer} value={v_KdiTermin_Dauer} />
            
               </Col>



            

               <Col sm="12">
                 <span>Anrede</span> <br></br>
                 <Form.Control onChange={(e) => setv_Kunde_Anrede(e.target.value)} value={v_Kunde_Anrede} type="text" /> <br></br>
               </Col>

               <Col sm="12">
                 <span>Name</span> <br></br>
                 <Form.Control onChange={(e) => setv_Kunde_Name1(e.target.value)} value={v_Kunde_Name1} type="text" /> <br></br>
               </Col>

           

               <Col sm="12">
                 <span>Straße</span> <br></br>
                 <Form.Control onChange={(e) => setv_Kunde_Strasse(e.target.value)} value={v_Kunde_Strasse} type="text" /> <br></br>
               </Col>


               <Col sm="12">
                 <span>Postleitzahl</span> <br></br>
                 <Form.Control onChange={(e) => setv_Kunde_Postleitzahl(e.target.value)} value={v_Kunde_Postleitzahl} type="text" /> <br></br>
               </Col>

               <Col sm="12">
                 <span>Ort</span> <br></br>
                 <Form.Control onChange={(e) => setv_Kunde_Ort(e.target.value)} value={v_Kunde_Ort} type="text" /> <br></br>
               </Col>

               <Col sm="12">
                 <span>E-Mail</span> <br></br>
                 <Form.Control onChange={(e) => setv_Kdi_BesuchEMail(e.target.value)} value={v_Kdi_BesuchEMail} type="text" /> <br></br>
               </Col>



               <Col sm="12">
                 <span>Landeskennzeichen</span> <br></br>
                 <Form.Control onChange={(e) => setv_Kunde_Landeskennz(e.target.value)} value={v_Kunde_Landeskennz} type="text" /> <br></br>
               </Col>
               
             </Form.Group>
             </Form>
)}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSpeichern}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showTage} onHide={handleCloseTage}>
        <Modal.Header closeButton>
          <Modal.Title>Tageseinstellungen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Col sm="12">
                 <span>Tage vor Auftragsdatum bis Terminvorschlag an Kunde versendet wird</span> <br></br>
                 <Form.Control onChange={(e) => setvorschlag(e.target.value)} value={vorschlag} type="text" /> <br></br>
               </Col>
               <Col sm="12">
                 <span>Tage vor Auftragsdatum für erste Terminerinnerung</span> <br></br>
                 <Form.Control onChange={(e) => seterste(e.target.value)} value={erste} type="text" /> <br></br>
               </Col>
               <Col sm="12">
                 <span>Tage vor Auftragsdatum für zweite Terminerinnerung</span> <br></br>
                 <Form.Control onChange={(e) => setzweite(e.target.value)} value={zweite} type="text" /> <br></br>
               </Col>
               <Col sm="12">
                 <span>Tage vor Auftragsdatum für dritte Terminerinnerung</span> <br></br>
                 <Form.Control onChange={(e) => setdritte(e.target.value)} value={dritte} type="text" /> <br></br>
               </Col>
               <Col sm="12">
                 <span>Tage vor Auftragsdatum für Eskalationsmail</span> <br></br>
                 <Form.Control onChange={(e) => seteskalation(e.target.value)} value={eskalation} type="text" /> <br></br>
               </Col>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={handleCloseTageSpeichern}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>

      
<Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Optionen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {LoadingModal === true ? (
 <Row>
 <Col  xs={12} className="text-center">
 <Spinner animation="border" variant="primary" />
 </Col>

</Row>
) : (
        <div className="d-grid gap-2">
        <Button size="lg" variant="success" onClick={handleBestaetigen}>
            Termin bestätigen
          </Button>
          <Button size="lg" variant="secondary" onClick={handleNeuerTermin}>Neuer Termin</Button>
            
            {InputField === true ? (
             <Row>
             
              
               <Col sm="8">
               <span>Auftragsdatum:</span><br></br><DatePicker disableCalendar={true} format={"dd-MM-yyyy"} onChange={tre} value={startDate} /><br></br><br></br>
               <span>Startuhrzeit:</span><br></br><TimePicker disableClock={true} format={"HH:mm"} onChange={onChange} value={value} /><br></br><br></br>
               <span>Dauer:</span><br></br><TimePicker disableClock={true} format={"HH:mm"} onChange={onChangeee} value={valueee} /><br></br><br></br>
              
                 <Button  variant="info" onClick={bestaetigen}>
            Bestätigen
          </Button>
               </Col>
               </Row>
         
            
            ) : (null)}

<Button size="lg" variant="danger" onClick={handleTerminAbsagen}>Termin absagen</Button>
            
            {InputFieldZwei === true ? (
             <Row>
             
              
               <Col sm="8">
               <Form.Control onChange={(e) => setgrund(e.target.value)} placeholder='Grund der Absage...' as="textarea" rows={3} /> <br></br>
              
                 <Button  variant="danger" onClick={absagen}>
            Absagen
          </Button>
               </Col>
               </Row>
         
            
            ) : (null)}
         
         {Rechte === "admin" ? (
          <Button size="lg" variant="danger" onClick={handleDeleteTermin}>
          Termin löschen
        </Button>
         ) : (null)}

{Rechte === "admin" ? (
          <Button size="lg" variant="info" onClick={editSpaltenWerte}>
          Spaltenwerte bearbeiten
        </Button>
         ) : (null)}
          
          </div>

) }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Schließen
          </Button>
       
        </Modal.Footer>
      </Modal>

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Spaltenfilter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
        <Form>
      
      

<Form.Check 
        type="switch"
        label="Montageauftragsnummer"
        className="farbe"
        checked={DokumentenNummer === true ? true : false}
        onChange={() => filterc('DokumentenNummer')}
      /> 

<Form.Check 
        type="switch"
        label="Anrede"
        className="farbe"
        checked={Kunde_Anrede === true ? true : false}
        onChange={() => filterc('Kunde_Anrede')}
      /> 

<Form.Check 
        type="switch"
        label="Name"
        className="farbe"
        checked={Kunde_Name1 === true ? true : false}
        onChange={() => filterc('Kunde_Name1')}
      /> 

<Form.Check 
        type="switch"
        label="Vorgang"
        className="farbe"
        checked={Vorgang === true ? true : false}
        onChange={() => filterc('Vorgang')}
      /> 

<Form.Check 
        type="switch"
        label="Straße"
        className="farbe"
        checked={Kunde_Strasse === true ? true : false}
        onChange={() => filterc('Kunde_Strasse')}
      /> 

<Form.Check 
        type="switch"
        label="Land"
        className="farbe"
        checked={Kunde_Landeskennz === true ? true : false}
        onChange={() => filterc('Kunde_Landeskennz')}
      /> 

<Form.Check 
        type="switch"
        label="Postleitzahl"
        className="farbe"
        checked={Kunde_Postleitzahl === true ? true : false}
        onChange={() => filterc('Kunde_Postleitzahl')}
      /> 

<Form.Check 
        type="switch"
        label="Ort"
        className="farbe"
        checked={Kunde_Ort === true ? true : false}
        onChange={() => filterc('Kunde_Ort')}
      /> 

<Form.Check 
        type="switch"
        label="E-Mail"
        className="farbe"
        checked={Kdi_BesuchEMail === true ? true : false}
        onChange={() => filterc('Kdi_BesuchEMail')}
      /> 



<Form.Check 
        type="switch"
        label="Kundennummer"
        className="farbe"
        checked={Liefer_AdressNummer === true ? true : false}
        onChange={() => filterc('Liefer_AdressNummer')}
      /> 



<Form.Check 
        type="switch"
        label="Art"
        className="farbe"
        checked={KdiEingang_AnsprPart === true ? true : false}
        onChange={() => filterc('KdiEingang_AnsprPart')}
      /> 

<Form.Check 
        type="switch"
        label="Auftragsbeschreibung"
        className="farbe"
        checked={Kdi_AuftrBeschreibng === true ? true : false}
        onChange={() => filterc('Kdi_AuftrBeschreibng')}
      /> 

<Form.Check 
        type="switch"
        label="Termindatum"
        className="farbe"
        checked={KdiTermin_Datum === true ? true : false}
        onChange={() => filterc('KdiTermin_Datum')}
      /> 

<Form.Check 
        type="switch"
        label="Terminuhrzeit"
        className="farbe"
        checked={KdiTermin_Uhrzeit === true ? true : false}
        onChange={() => filterc('KdiTermin_Uhrzeit')}
      /> 



<Form.Check 
        type="switch"
        label="Planstunden"
        className="farbe"
        checked={KdiTermin_Dauer === true ? true : false}
        onChange={() => filterc('KdiTermin_Dauer')}
      /> 





<Form.Check 
        type="switch"
        label="Terminbestätigungsdatum"
        className="farbe"
        checked={TerminBestaetigungsdatum === true ? true : false}
        onChange={() => filterc('TerminBestaetigungsdatum')}
      /> 
      



        
       
        </Form>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>

      <br/>
{Loading === true ? (
 <Row>
 <Col  xs={12} className="text-center">
 <Spinner animation="border" variant="primary" />
 </Col>

</Row>
) : ( null) }

<Row>
<Col xs={7} md={4} lg={3} sm={7}>
  <img className='img-fluid' src={bild}/> 
  </Col>
 <Col onClick={logOut} xs={{ span: 2, offset: 6}} className="text-center p-3 mb-2 umrandung">
 <i class="bi bi-box-arrow-right stil"></i>  Logout
 </Col>

</Row>


<br/><br/><br/>

<Row>
 <Col onClick={handleShowTage} xs={{ span: 2, offset: 9}} className="text-center p-3 mb-2 umrandung">
 <i class="bi bi-gear-fill stil"></i>  Einstellungen Tage
 </Col>

</Row>

<Row className="text-center justify-content-center">
  <Col xs={12} md={6}>
  <InputGroup className="mb-3">
      
        <input 
          placeholder="Suchen..."
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={(e) => search(e)}
          className={'hintergrund col-10'}
          
        />
           <button className={'hintergrund'} id="button-addon2">
          Suchen 
        </button>
        </InputGroup>
  </Col>
 
</Row>
<br/> <br></br>

<br></br>
<Row className="justify-content-center">
  <Col className="d-grid gap-2" xs={12} md={3}>
  <Form.Check 
            type={'radio'}
            label={'Nur bestätigte Termine anzeigen'}
            name={'opt'}
            onChange={() => bestTermine()}
          />

          
<Form.Check 
            type={'radio'}
            label={'Nur unbestätigte Termine anzeigen'}
            name={'opt'}
            onChange={() => ubTermine()}
          />

<Form.Check 
            type={'radio'}
            label={'Nur abgesagte Termine anzeigen'}
            name={'opt'}
            onChange={() => abTermine()}
          />

          
<Form.Check 
            type={'radio'}
            label={'Nur überfällige Termine anzeigen'}
            name={'opt'}
            onChange={() => faelligeTermine()}
          />

  </Col>
</Row>
<br></br>


{currentPosts.length < 1 ? (
  <Row className="text-center justify-content-center">
     <Col xs={12}>
     <br></br><br></br><br></br><br></br>
 <h3>Keine Datensätze vorhanden.</h3>
 </Col>
 </Row>
) : (

<div>

<Row className="justify-content-center">
  <Col className="d-grid gap-2" xs={12} md={3}>
  <button className={'hintergrund'} onClick={handleShow}  size="lg"><i class="bi bi-filter-left"></i> Spaltenfilter</button>
  </Col>
</Row>


<br/><br></br><br></br>


<Row>
        <Col xs={12}>

        <Table striped responsive bordered hover>
      <thead>
        <tr>
        {TerminBestaetigungsdatum === true ? (<th>Terminbestätigungsdatum</th>) : (null)}
        {DokumentenNummer === true ? (<th>Montageauftragsnummer</th>) : (null)}
        {Vorgang === true ? (<th>Vorgangsnummer</th>) : (null)}
        {Liefer_AdressNummer === true ? (<th>Kundennummer</th>) : (null)}
        {KdiEingang_AnsprPart === true ? ( <th>Versandart</th>) : (null)}
        {Kdi_AuftrBeschreibng === true ? (<th>Auftragsbeschreibung</th>) : (null)}
        {KdiTermin_Datum === true ? ( <th>Auftragsdatum</th>) : (null)}
        {KdiTermin_Uhrzeit === true ? (<th>Beginn</th>) : (null)}
        {KdiTermin_Dauer === true ? ( <th>Planstunden</th>) : (null)}
        {Kunde_Anrede === true ? (<th>Anrede</th>) : (null)}
       {Kunde_Name1 === true ? ( <th>Name</th>) : (null)}
       {Kunde_Strasse === true ? (<th>Straße</th>) : (null)}
       {Kunde_Landeskennz === true ? (<th>Land</th>) : (null)}
       {Kunde_Postleitzahl === true ? (<th>PLZ</th>) : (null)}
       {Kunde_Ort === true ? ( <th>Ort</th>) : (null)}
       {Kdi_BesuchEMail === true ? ( <th>Mail</th>) : (null)}
     
        </tr>
      </thead>
      <tbody>
      {
  
      
     
        currentPosts.map((v, index) => (
        <tr className={(v.status == 'green' ? 'p-3 mb-2 bg-successs' : (v.status == 'yellow' ? 'p-3 mb-2 bg-danger' : (v.status == 'blue' ? 'blue' : 'yellow')))} key={index} onClick={() => handleShowEdit(v.ID,
          v.TerminBestaetigungsdatum,
          v.Dokument_Nummer,
          v.Liefer_AdressNummer,
          v.KdiEingang_AnsprPart,
          v.Kdi_AuftrBeschreibng,
          v.KdiTermin_Datum,
          v.KdiTermin_Uhrzeit,
          v.KdiTermin_Dauer,
          v.Kunde_Anrede,
          v.Kunde_Name1,
          v.Kunde_Strasse,
          v.Kunde_Landeskennz,
          v.Kunde_Postleitzahl,
          v.Kunde_Ort,
          v.Kdi_BesuchEMail,
          v.Vorgang
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        )}>
       {TerminBestaetigungsdatum === true ? ( <td >{v.TerminBestaetigungsdatum}</td>) : (null)}
       {DokumentenNummer === true ? (<td >{v.Dokument_Nummer}</td>) : (null)}
       {Vorgang === true ? (<td >{v.Vorgang}</td>) : (null)}
       {Liefer_AdressNummer === true ? ( <td >{v.Liefer_AdressNummer}</td>) : (null)}
       {KdiEingang_AnsprPart === true ? ( <td >{v.KdiEingang_AnsprPart}</td>) : (null)}
       {Kdi_AuftrBeschreibng === true ? ( <td >{v.Kdi_AuftrBeschreibng}</td>) : (null)}
       {KdiTermin_Datum === true ? ( <td >{v.KdiTermin_Datum}</td>) : (null)}
       {KdiTermin_Uhrzeit === true ? ( <td >{v.KdiTermin_Uhrzeit}</td>) : (null)}
       {KdiTermin_Dauer === true ? (  <td >{v.KdiTermin_Dauer}</td>) : (null)}
       {Kunde_Anrede === true ? ( <td >{v.Kunde_Anrede}</td>) : (null)}
       {Kunde_Name1 === true ? ( <td >{v.Kunde_Name1}</td>) : (null)}
       {Kunde_Strasse === true ? ( <td >{v.Kunde_Strasse}</td>) : (null)}
       {Kunde_Landeskennz === true ? ( <td >{v.Kunde_Landeskennz}</td>) : (null)}
       {Kunde_Postleitzahl === true ? ( <td >{v.Kunde_Postleitzahl}</td>) : (null)}
       {Kunde_Ort === true ? (<td >{v.Kunde_Ort}</td>) : (null)}
       {Kdi_BesuchEMail === true ? ( <td >{v.Kdi_BesuchEMail}</td>) : (null)}
      
      
          </tr>
        )  
      )}

      

      
    
        
        
      </tbody>
    </Table>



        </Col>
      </Row>
<br/> <br></br>
      <div className='text-center'>
        <Row className='text-center justify-content-center'>
        <Col className='text-center justify-content-center' xs={3} md={1}>
      <select className={'hintergrund'} onChange={(e => seiten(e))} value={postsPerPage} aria-label="Default select example">
      <option value="1">1</option>
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="15">15</option>
      <option value="20">20</option>
      <option value="30">30</option>
      <option value="50">50</option>
      <option value="100">100</option>

    </select>
    </Col>
    </Row>
    <br/> <br></br>
    {alle === true ? (null) : (
      <div className='hintergrund'>
  {currentPosts.length >= 1 ? (<p>Seite: {currentPage}</p>) : (null)}
  <br></br>
    <ul className='pagination justify-content-center hintergrund'>
      {pageNumbers.map(number => (
        <li key={number}  className={'page-item hintergrund'}>
          <a onClick={() => paginate(number)} className={'page-link hintergrund'}>
            {number}
          </a>
        </li>
      ))}
      
    </ul>
   
    
    </div>
    
    )}
      
    
       
      </div>
      <br></br><br></br><br></br><br></br>
      </div>

)

}

     
     
     
    </Container>
  );
}



export default App;
