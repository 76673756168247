import React, {useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap-icons/font/bootstrap-icons.css';
import bild from  '../softgewerk.png';
import Spinner from 'react-bootstrap/Spinner';
import {
  Link
} from "react-router-dom";
import '../App.css';

const Bestaetigt = () => {

  const [bes, setbes] = useState(false);
  const [LoadingModal, setLoadingModal] = useState(false);
  const [text, settext] = useState('');
  const [grund, setgrund] = useState('');
  const [Termine, setTermine] = useState('');
  const [absage, setabsage] = useState(false);
  const [versch, setversch] = useState(false);
  const [verschbes, setverschbes] = useState(false);
  const [abbes, setabbes] = useState(false);

    var { id } = useParams();

    useEffect(() => {

      const infos = async () => {
        setLoadingModal(true);
        try {
                const res = await axios.get('http://wartung.hundertmark-bad.de/infos.php', {
                params: { id: id}, 
                
                
                });
                
                
          
               let response = res.data.text;
               
               settext(response);
               setLoadingModal(false);
               
              } catch (err) {
                if (err.response.status === 500) {
                  alert('There was a problem with the server');
                } else {
                  alert(err.response.data.msg);
                }
                
              } 
  
              
            }
  
      infos();
    }, []);

    const external = () => {
      window.open('https://www.hundertmark-bad.de', '_blank');
    }

    const onLoad = async () => {
      setLoadingModal(true);
      try {
              const res = await axios.get('http://wartung.hundertmark-bad.de/done.php', {
              params: { id: id}, 
              
              
              });
              
              
        
             let response = res.data.best;
             
             setbes(true);
             setLoadingModal(false);
             
            } catch (err) {
              if (err.response.status === 500) {
                alert('There was a problem with the server');
              } else {
                alert(err.response.data.msg);
              }
              
            } 

            
          }

          const absagee = () => {
            setabsage(true);
          }

          const verschi = () => {
            setversch(true);
          }



          const  absageee = async () => {
            

            setLoadingModal(true);
            try {
                    const res = await axios.post('http://wartung.hundertmark-bad.de/absage.php', {
                    id: id, grund: grund});
                    
                    
                 
                    
                    
              
                   let response = res.data.best;
                   
                 
                   setLoadingModal(false);
                   setabbes(true);
                   
                  } catch (err) {
                    if (err.response.status === 500) {
                      alert('There was a problem with the server');
                    } else {
                      alert(err.response.data.msg);
                    }
                    
                  } 
          }


          
          const  verschieben = async () => {
            

            setLoadingModal(true);
            try {
                    const res = await axios.post('http://wartung.hundertmark-bad.de/verschieben.php', {
                    id: id, Termine: Termine});
                    
                    
                 
                    
                    
              
                   let response = res.data.best;
                   
                 
                   setLoadingModal(false);
                   setverschbes(true);
                   
                  } catch (err) {
                    if (err.response.status === 500) {
                      alert('There was a problem with the server');
                    } else {
                      alert(err.response.data.msg);
                    }
                    
                  } 
          }
 
 

  return (
    <Container className='hintergrundd'>
        <br></br>
      <Row className="text-center justify-content-center">
  <Col xs={7} md={2}  >
  <h3>Serviceportal</h3><br></br>
  <img className='img-fluid' src={bild}/>
  </Col>
</Row>
<br/>

<Row>
    <Col xs={12} className="text-center">

    {LoadingModal === true ? (
        <Row>
        <Col  xs={12} className="text-center">
        <Spinner animation="border" variant="primary" />
        </Col>
       
       </Row>
       ) : (
 
    bes === false && absage === false && versch === false? ( 
     
     <div>
      <br></br><br></br><br></br><br></br><br></br>
    <h4>Bitte klicken Sie auf den "Bestätigen" Button, um Ihren Termin zu bestätigen, oder auf den "Absagen" 
      Button, um Ihren Termin abzusagen</h4><br></br>
   
   
   
   {text === '' ? (null) : (
   <h4>Termin: {text}</h4>

   
   )
}
<br></br>
   
    <div class="d-grid col-md-3 col-5 mx-auto">
      <button onClick={onLoad} type="button"  class="btnn">Bestätigen</button><br></br>
      <button onClick={absagee} type="button"  class="btnn">Absagen</button><br></br>
      <button onClick={verschi} type="button"  class="btnn">Verschieben</button>
      
      </div>
    </div>
 
    )
     : absage && !abbes  ? (
      <div class="d-grid col-md-3 col-5 mx-auto">
      <p>Beschreiben Sie den Grund der Absage: </p><br></br>
      <Col xs={12}>
      <Form.Control onChange={(e) => setgrund(e.target.value)} placeholder='Grund der Absage...' as="textarea" rows={3} /> <br></br>
      </Col>
      <button onClick={absageee} type="button"  class="btnn">Absagen</button>
     </div>

    )  : (versch && !verschbes ? (
      <div class="d-grid col-md-3 col-5 mx-auto">
      <p>Bitte nennen Sie uns drei alternative Termine für die Wartung, wir kommen dann wieder auf Sie zu: </p><br></br>
      <Col xs={12}>
      <Form.Control onChange={(e) => setTermine(e.target.value)} placeholder='Alternative Termine...' as="textarea" rows={3} /> <br></br>
      </Col>
      <button onClick={verschieben} type="button"  class="btnn">Absenden</button>
     </div>

    ) : verschbes ? (
      <div>
      <p>Vielen Dank für Ihre Nachricht, diese wurde an uns übermittelt, wir werden wieder auf Sie zukommen.</p><br></br>
    
     </div>

    ) : abbes ? (
      <div>
      <p>Vielen Dank für Ihre Absage, diese wurde an uns übermittelt, wir werden wieder auf Sie zukommen.</p><br></br>
    
     </div>

    ):  (
        <div>
  <i className="bi bi-check-circle bigger"></i>
  <br></br><br></br>
  <b>Vielen Dank für Ihre Bestätigung!</b> <br></br><br></br>

<p>Unser Servicetechniker wird wie vereinbart am {text} Uhr (+/- 2 Stunde) bei Ihnen eintreffen, um die Wartung durchzuführen und sicherzustellen, dass <br></br>
Ihre Anlage effizient und ausfallsicher funktioniert. Vor 7 Uhr finden keine Termine statt.
<br></br>
<br></br>



  
</p>
</div>
    )
    ))
     }

   

  
    </Col>
    <br></br>
    <br></br>
    <Col xs={12} className="text-center">
  <br></br>  <br></br>  <br></br>  <br></br>  <br></br>  <br></br>  <br></br>  <br></br>  
<br></br>

<a style={{ color: 'blue'}}  onClick={external}>www.hundertmark-bad.de</a>

    </Col>
</Row>

    </Container>
  )
}

export default Bestaetigt